
/**
 * Add a new image, depending on the route
 * @param route
 */

const ajaxRoot = [process.env.MIX_APP_SUBPATH, '/ajax'].join('');

exports.addImageInput = function(route) {
  $.ajax({
    url: ajaxRoot + '/addimage',
    type: 'POST',
    data: {
      counter: $('.input-image').length,
      route: route,
      _token: $('meta[name="csrf-token"]').attr('content'),
    },
    dataType: 'json',
    success: function(data) {
      $(data.inputForm).insertBefore('#add-image');
      $('.remove-image').bind('click', function() {
        exports.removeImageInput(this);
      });
    },
    error: function(xhr, status, error) {
      console.log(xhr.responseText);
    },
  });
};

/**
 * Remove an image input
 * @param button
 */
exports.removeImageInput = function(button) {
  $(button)
    .parents('.input-image')
    .remove();
};

let rtlLanguages = [
  '(Mittelpersisch) Pahlavī',
  'Alt-Avestisch',
  'Altarabisch',
  'Altavestisch',
  'Altpersisch/Awestisch',
  'Altsüdarabisch',
  'Altsüdarabisch minäische Inschrift',
  'Arabisch',
  'Aramäisch',
  'Aramäisch / Hebräisch',
  'Avestisch',
  'Griechisch(?)',
  'Hebäisch',
  'Hebräisch',
  'Hebräisch / (Aramäisch)',
  'Hebräisch / Aramäisch',
  'Hebräisch/ Aramäisch',
  'Hebräisch/(Aramäisch)',
  'Hebräisch/Aramäisch',
  'Jung-Avestisch',
  'Mandäisch',
  'Mittelpersisch (Pahlavī)',
  'Palmyrenisch',
  'Südarabisch',
  'Syrisch',
];

export default rtlLanguages;

let imageMethods = require('./images-methods');

$('#add-image').on('click', function() {
  let route = $(this).attr('route');
  imageMethods.addImageInput(route);
});

$('.remove-image').on('click', function() {
  imageMethods.removeImageInput(this);
});
